import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Snatch Balance 5×2`}</p>
    <p>{`Hang Power Snatch 5×2\\@90% 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`10-8-6-4-2 reps each:`}</p>
    <p>{`Pistols (total)`}</p>
    <p>{`Power Snatch (115/75)(RX+ 135/95)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      